import React, { Component } from 'react';
import { Checkbox } from 'antd';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { get } from 'lodash';

import './OrderInfo.scss';
import TimeIcon from '../../assets/images/icon_time.svg';
import BookIcon from '../../assets/images/icon_book.svg';
import OwnerIcon from '../../assets/images/icon_owner.svg';
import {
  currencyFormatter,
  formatDurationText,
  getTranslatedText,
  checkIsEventDay,
} from '../../services/appService';
import {
  buyCourseAction,
  depositAction,
  getCourseDetailAction,
} from '../../actions/courses';
import * as types from '../../actions/index';
import { getProfileAction } from '../../actions/profile';

import { routes, toastDuration } from '../../constants';
import { toast } from 'react-toastify';


class OrderInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet: 0,
      need_purchase: 0,
      join: false,
      join_fee: 0,
      receive: 0,
      isDayOfEvent: false,
      isCourseInEvent: false,
      eventSellInfo: {},
    };
  };

  componentDidMount() {
    this.props.actions.getProfileAction({
      token: localStorage.getItem(types.TOKEN_KEY),
    });
    let courseId = parseInt(get(this.props, 'match.params.id'), 0);
    this.props.actions.getCourseDetailAction(courseId);
    let checkIsDayOfEvent = checkIsEventDay(this.props.eventStartDate, this.props.eventEndDate);
    this.setState({ isDayOfEvent: checkIsDayOfEvent });
    let isCourseInEvent = false;
    let sellInfo = {
      soldNum: 0,
      total: 0,
    };
    this.props.eventCourses?.forEach(eventCourse => {
      if (courseId === eventCourse.course_id) {
        isCourseInEvent = true;
        sellInfo.total = eventCourse.total;
        sellInfo.soldNum = eventCourse.sold_number;
      };
    });
    this.setState({isCourseInEvent: isCourseInEvent, eventSellInfo: sellInfo});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { courseDetail } = nextProps;
    if (courseDetail.price && !this.state.receive) {
      let insurance = parseInt(get(this.props, 'match.params.insurance'), 0);
      if (insurance > 0) {
        this.setState({
          join: true,
          join_fee: courseDetail.price / 10,
        });
      }

      this.setState({ receive: 1 });
    }
  }

  pay = course_id => {
    if (!window.confirm(getTranslatedText('buy_course_confirm'))) return;
    const { courseDetail, profile } = this.props;
    let wallet = profile.total_price;
    let need_purchase = courseDetail.price;
    if (this.state.isDayOfEvent && this.state.isCourseInEvent && this.state.eventSellInfo?.soldNum < this.state.eventSellInfo?.total) {
      need_purchase = (courseDetail.price*(100 - this.props.eventDiscount))/100;
    } else {
      if (profile.max_price > 0 && courseDetail.is_english) {
        need_purchase = courseDetail.promotion_price;
      };
      if (courseDetail.promotion_price !== courseDetail.price) {
        need_purchase = courseDetail.promotion_price;
      };
    };
    // if (this.state.join)
    //   need_purchase += this.state.join_fee;

    if (wallet < need_purchase) {
      toast.error(getTranslatedText('wallet_not_enough'));
      setTimeout(() => {
        window.location.href = routes.accountDeposit;
      }, toastDuration);

      return;
    }
    this.props.actions.buyCourseAction(course_id, false);
  };

  checkingJoinInsurance = event => {
    const { courseDetail } = this.props;
    let payload = {
      join: event.target.checked,
      join_fee: this.state.join_fee,
    };
    if (event.target.checked)
      payload.join_fee = (courseDetail.price / 10);
    else
      payload.join_fee = 0;
    this.setState(payload);
  };

  render() {
    const { courseDetail, profile, eventDiscount, } = this.props;
    if (courseDetail.is_english) {
      if (profile.max_price_english >= courseDetail.price) {
        alert('You already own this course!');
        window.location.href = routes.accountCourses;
      }
    } else {
      if (profile.max_price >= courseDetail.price){
        alert('You already own this course!');
        window.location.href = routes.accountCourses;
      }
    }
    const lang = localStorage.getItem('current_lang');

    let wallet = profile.total_price;
    let course_price = (profile.max_price > 0 && courseDetail.is_english) ? courseDetail.promotion_price : courseDetail.price;
    let currency = 'USD';

    return (
        <div className='OrderInfoContainer'>
          <div className='Title'>
            {getTranslatedText('your_order_info')}
          </div>
          <div className='OrderInfo'>
            <div className='CourseInfo'>
              <div>{getTranslatedText('course_name')}</div>
              <div className='CourseHeader'>
                <div className='HeaderText'>
                  {lang == 'vi'
                      ? courseDetail.title_vi
                      : courseDetail.title}
                </div>
                <div className='LevelPrice'>
                  <div className='LevelContainer'>
                    <div className='Text'>
                      {getTranslatedText('Level')}
                    </div>
                    <div className='Level'>{courseDetail.level}</div>
                  </div>                  
                  <div className='PriceContainer'>
                    <div className='Text'>
                      {getTranslatedText('Price')}
                    </div>
                    {this.state.isDayOfEvent && this.state.isCourseInEvent ? (
                      <div 
                        className='NumberContainer'
                        style={{backgroundColor: '#d59e29'}}
                      >
                        <div className='PriceNumber'>
                          {this.state.eventSellInfo?.total <= this.state.eventSellInfo?.soldNum ? (
                            <span>
                              {currencyFormatter(courseDetail.price, true)}
                            </span>
                          ) : (
                            <span>
                              {currencyFormatter((courseDetail.price*(100 -eventDiscount))/100, true)}
                            </span>
                          )}
                          <sup className='Currency'>{currency}</sup>
                        </div>
                      </div>
                    ) : (
                      <div 
                        className='NumberContainer'
                        style={courseDetail.promotion_price !== courseDetail.price ? {backgroundColor: '#FFD371'} : {backgroundColor: '#d59e29'}}
                      >
                        <div className='PriceNumber'>
                      <span>
                        {currencyFormatter(courseDetail.price, true)}
                      </span>
                          <sup className='Currency'>{currency}</sup>
                        </div>
                      </div>
                    )}
                  </div>
                  {courseDetail.promotion_price !== courseDetail.price && (
                    <div className='PriceContainer'>
                      <div className='Text'>
                        {getTranslatedText('promotion_price')}
                      </div>
                      <div className='NumberContainer'
                            style={{
                              backgroundColor: '#d59e29'
                            }}
                      >
                        <div className='PriceNumber'>
                      <span>
                        {currencyFormatter(courseDetail.promotion_price, true)}
                      </span>
                          <sup className='Currency'>{currency}</sup>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='Info'>
                <div className='Container'>
                  <img alt='time' src={TimeIcon}></img>
                  <div className='Text'>
                    {getTranslatedText('duration')}{' '}
                    <span>
                    {formatDurationText(courseDetail.duration)}
                  </span>
                  </div>
                </div>
                <div className='Container'>
                  <img alt='book' src={BookIcon}></img>
                  <div className='Text'>
                    {getTranslatedText('content')}{' '}
                    <span>{courseDetail.total_video} videos</span>
                  </div>
                </div>
                <div className='Container'>
                  <img alt='own' src={OwnerIcon}></img>
                  <div className='Text'>
                    {getTranslatedText('Lifetime')}
                  </div>
                </div>
              </div>
            </div>
            <div className='WalletInfoContainer'>
              <div className='WalletTitle'>
                {getTranslatedText('your_wallet')}
              </div>
              <div className='WalletInfo'>
                <div className='WalletInfoItems'>
                  <div className='Container Remaining'>
                    <div className='Text'>
                      {getTranslatedText('Balance')}
                    </div>
                    <div className='Number'>
                      {currencyFormatter(wallet, true)}
                    </div>
                    <div className='Currency'>{currency}</div>
                  </div>
                  {this.state.isDayOfEvent && this.state.isCourseInEvent ? (
                    <div className='Container PendingOrder'>
                      <div className='Text'>
                        {getTranslatedText('need_purchase')}
                      </div>
                      {this.state.eventSellInfo?.total <= this.state.eventSellInfo?.soldNum ? (
                        <div className='Number'>
                          {currencyFormatter(courseDetail.price, true)}
                        </div>
                      ) : (
                        <div className='Number'>
                          {currencyFormatter((courseDetail.price * (100 - eventDiscount))/100, true)}
                        </div>
                      )}
                      <div className='Currency'>{currency}</div>
                    </div>
                  ) : (
                    <>
                      {courseDetail.promotion_price !== courseDetail.price ? (
                          <div className='Container PendingOrder'>
                            <div className='Text'>
                              {getTranslatedText('need_purchase')}
                            </div>
                            <div className='Number'>
                              {currencyFormatter(courseDetail.promotion_price, true)}
                            </div>
                            <div className='Currency'>{currency}</div>
                          </div>
                      ) : (
                          <div className='Container PendingOrder'>
                            <div className='Text'>
                              {getTranslatedText('need_purchase')}
                            </div>
                            <div className='Number'>
                              {currencyFormatter(course_price, true)}
                            </div>
                            <div className='Currency'>{currency}</div>
                          </div>
                      )}
                    </>
                  )}
                  {/*<div className='Container PendingOrder'>*/}
                  {/*  <div className='Text'>*/}
                  {/*    <Checkbox onChange={this.checkingJoinInsurance}*/}
                  {/*              checked={this.state.join} /> {getTranslatedText('insurance_purchase')}*/}
                  {/*  </div>*/}
                  {/*  <div className='Number'>*/}
                  {/*    {currencyFormatter(this.state.join_fee, true)}*/}
                  {/*  </div>*/}
                  {/*  <div className='Currency'>{currency}</div>*/}
                  {/*</div>*/}
                  {/*<div className="Container Missing">*/}
                  {/*  <div className="Text">*/}
                  {/*    {getTranslatedText('amount_top_up')}*/}
                  {/*  </div>*/}
                  {/*  <div className="Number">*/}
                  {/*    {currencyFormatter(*/}
                  {/*      course_price - wallet < 0*/}
                  {/*        ? 0*/}
                  {/*        : course_price - wallet,*/}
                  {/*      true,*/}
                  {/*    )}*/}
                  {/*  </div>*/}
                  {/*  <div className="Currency">{currency}</div>*/}
                  {/*</div>*/}
                </div>
                <div
                  className='CTAButton'
                  onClick={() => {
                    if (courseDetail.promotion_price !== courseDetail.price) {
                      this.pay(
                          courseDetail.id,
                          courseDetail.promotion_price,
                          profile.total_price,
                      )
                    } else {
                      this.pay(
                          courseDetail.id,
                          courseDetail.price,
                          profile.total_price,
                      )
                    }
                  }}
                >
                  {getTranslatedText('purchase_now')}
                </div>
              </div>
            </div>
          </div>
          <div className='TextNotice'>
            {getTranslatedText('note_after_payment')}
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    courseDetail: get(state, 'courses.courseDetail', {}),
    profile: get(state, 'profile.data'),
    eventCourses: state.eventWeek.activeEventData?.courses,
    eventStartDate: state.eventWeek.activeEventData?.start,
    eventEndDate: state.eventWeek.activeEventData?.end,
    eventDiscount: state.eventWeek.activeEventData?.discount,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
        {
          buyCourseAction,
          depositAction,
          getProfileAction,
          getCourseDetailAction,
        },
        dispatch,
    ),
  };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(OrderInfo);

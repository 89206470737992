import React, { useState } from 'react';
import {
  Checkbox, Layout, Modal, Row,
} from 'antd';
import {
  FileText,
  Clock,
  PlayCircle,
  RefreshCcw,
  DollarSign,
} from 'react-feather';

import './styles/course-information-styles.scss';
import { Link } from 'react-router-dom';
import { routes } from '../../../../constants';
import { formatDurationText, getTranslatedText } from '../../../../services/appService';
import logo from '../../../../assets/images/logo.png';
import head_phone from '../../../../assets/images/icons/head_phone.svg';
import SellInfo from '../../../../components/GoldenWeekPromotion/components/common/Promotion/SellInfo';
import PromotionDiscount from '../../../../components/GoldenWeekPromotion/components/common/Promotion/Discount';
import PromotionPrice from '../../../../components/GoldenWeekPromotion/components/common/Promotion/Price';

function CourseInformation(props) {

  const {
    courseDetail,
    isThisCoursesBought,
    whichRender,
    displayEventPrice,
    eventDiscount,
    eventSellInfo,
  } = props;

  const [insurance, setInsurance] = useState(1);
  const [checked, setChecked] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectionMenu, setSelectionMenu] = useState(1);

  function handleOnCheckboxChange(event) {
    if (event.target.checked === false) {
      setInsurance(0);
    } else {
      setInsurance(1);
    }
    ;
  };

  function buyInsurance() {
    window.location.href = routes.buyInsurance.replace(':id', courseDetail.id);
  }

  return (
    <React.Fragment>
      <div
        className='course-information-wrapper'
      >
        <div className='title-course-information-wrapper'>
          <h4 id='text-title-course-information-wrapper'>
            {getTranslatedText('course_info')}
          </h4>
          <h4 id='course-name-title-course-information-wrapper'>
            {courseDetail.level}
          </h4>
        </div>
        <div id='login-price-control-course-information-wrapper'>
          <p id='price-login-price-control-course-information-wrapper'>
            <DollarSign /> {getTranslatedText('course_price')}
          </p>
          {displayEventPrice ? (
            <>
            {eventSellInfo.total <= eventSellInfo.soldNum ? (
              <div className='bg-gold rounded-xl max-w-max px-7 py-1.5 flex items-center gap-2'>
                <p className="text-white text-sm md:text-md lg:text-xl">{courseDetail.price}$</p>
              </div>
            ) : (
              <PromotionPrice
                total={eventSellInfo.total}
                soldNum={eventSellInfo.soldNum}
                containerClassName="bg-gold rounded-2xl max-w-max px-7 py-1.5 flex items-center gap-2 mb-2"
                discountPrice={(courseDetail.price*(100 - eventDiscount))/100}
                stockPrice={courseDetail.price}
              />
            )}
              <PromotionDiscount
                containerClassName="block mb-2"
                discount={eventDiscount}
              />
            </>
          ) : (
            <div id='control-login-price-control-course-information-wrapper'>
              <div className='item-control-login-price-control-course-information-wrapper'>
                {courseDetail.promotion_price !== courseDetail.price ? (
                  <div
                    className='price-item-control-login-price-control-course-information-wrapper'
                    style={{
                      backgroundColor: '#FFD371'
                    }}
                  >
                    <p>{courseDetail.price}$</p>
                  </div>
                ) : (
                  <div className='price-item-control-login-price-control-course-information-wrapper'
                    style={{
                      backgroundColor: '#d59e29'
                    }}
                  >
                    <p>{courseDetail.price}$</p>
                  </div>
                )}
                <p>{getTranslatedText('price')}</p>
              </div>
              {(courseDetail.promotion_price !== courseDetail.price) && (
                <div className='item-control-login-price-control-course-information-wrapper'>
                  <div className='price-item-control-login-price-control-course-information-wrapper'
                    style={{
                      backgroundColor: '#d59e29'
                    }}
                  >
                    <p>{courseDetail.promotion_price}$</p>
                  </div>
                  <p>{getTranslatedText('promotion_price')}</p>
                </div>
              )}
            </div>
          )}
        </div>
        {displayEventPrice && (
          <SellInfo
            containerClassName="bg-gold-50 bg-opacity-50 rounded-2xl w-60 h-8 mx-auto flex items-center justify-center divide-x divide-grey mb-2"
            textSize="text-10"
            numberSize="text-sm"
            soldNum={eventSellInfo.soldNum}
            remainNum={eventSellInfo.remainNum}
            total={eventSellInfo.total}
          />
        )}
        <div
          id='content-course-information-wrapper'
        >
          <div className='item-content-course-information-wrapper'>
            <div className='icon'>
              <FileText />
            </div>
            <p className='text'>{getTranslatedText('lessons_are_included')}</p>
            <p className='number'>{courseDetail.total_course}</p>
          </div>
          <div className='item-content-course-information-wrapper'>
            <div className='icon'>
              <img src={head_phone} alt='' />
            </div>
            <p className='ml-1 text'>{getTranslatedText('audio_license_title')}</p>
            <p className='number'>{courseDetail.price < 500 ? `1 ${getTranslatedText('year_title')}` : getTranslatedText('lifetime')}</p>
          </div>
          <div className='item-content-course-information-wrapper'>
            <div className='icon'>
              <PlayCircle />
            </div>
            <p className='text'>Video</p>
            <p className='number'>{courseDetail.total_video}</p>
          </div>
          <div className='item-content-course-information-wrapper'>
            <div className='icon'>
              <Clock />
            </div>
            <p className='text'>{getTranslatedText('duration')}</p>
            <p className='number'>{formatDurationText(courseDetail.duration)}</p>
          </div>
          <div className='item-content-course-information-wrapper'>
            <div className='icon'>
              <RefreshCcw />
            </div>
            <p className='text'>{getTranslatedText('course_include')}</p>
            <p className='number'>{getTranslatedText('lifetime')}</p>
          </div>
        </div>
        {!isThisCoursesBought ? (
          <React.Fragment>
            {/*<div id='checkbox-control-course-information-wrapper'>*/}
            {/*  <Checkbox*/}
            {/*    onChange={(event) => {*/}
            {/*      handleOnCheckboxChange(event);*/}
            {/*      setChecked(!checked);*/}
            {/*    }}*/}
            {/*    checked={checked}*/}
            {/*  />*/}
            {/*  <p>{getTranslatedText('insurance_for_course')}</p>*/}
            {/*  <div id='price-checkbox-control-course-information-wrapper'>*/}
            {/*    <p>{courseDetail.price / 10}$</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div id='control-course-information-wrapper'>
              <Link
                to={whichRender === 'user-login' ? routes.courseOrder.replace(':id', courseDetail.id).replace(':insurance', (checked) ? 1 : 0) : routes.signin}
              >
                <button>
                  {getTranslatedText('purchase_now')}
                </button>
              </Link>
            </div>
          </React.Fragment>
        ) : null}
      </div>
      {(isThisCoursesBought && courseDetail.can_join_insurance == 1) && (
        <div className='InsurranceBox'>
          <div className='TitleContainer'>
            <div className='PrivilegesTitle'>
              <p>{getTranslatedText('user_promotion')}</p>
            </div>
            <div className='BoxInsurranceTitle'>
              <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_package') }}>
              </p>
            </div>
          </div>
          <div className='TitleFeeContainer'>
            <p>
              <b>{getTranslatedText('insurance_fee')}:</b>
            </p>
            <i>{getTranslatedText('insurance_fee_percent')}</i>
          </div>
          <div className='FeeBox'>
            <div className='Box'>
              <b>{courseDetail.price / 10}</b>
              <span>usd</span>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className='ButtonBuyContainer'>
            <div
              className='BuyButton'
              onClick={() => buyInsurance()}
            >
              <b>{getTranslatedText('want_buy')}</b>
            </div>
          </div>
          <br />
          <hr />
          <div className='QuestionContainer'>
            <p>
              <i onClick={() => setVisibleModal(true)}>
                {getTranslatedText('insurance_frequently')}
              </i>
            </p>
          </div>
        </div>
      )}
      <Modal
        visible={visibleModal}
        footer={false}
        width={1000}
        onCancel={() => setVisibleModal(false)}
        destroyOnClose={true}
        style={{ zIndex: '2' }}
      >
        <React.Fragment>
          <Layout.Header className='logo__container'>
            <img src={logo} width='150px' alt='' />
          </Layout.Header>
          <Layout.Content className='content__container'>
            <Row>
              <div className='wrapper__content'>
                <div className='left'>
                  <div className={'question ' + ((selectionMenu === 1) ? 'active' : '')}
                    onClick={() => setSelectionMenu(1)}>
                    {getTranslatedText('insurance_who')}
                  </div>
                  <div className={'question ' + ((selectionMenu === 2) ? 'active' : '')}
                    onClick={() => setSelectionMenu(2)}>
                    {getTranslatedText('insurance_long')}
                  </div>
                  <div className={'question ' + ((selectionMenu === 3) ? 'active' : '')}
                    onClick={() => setSelectionMenu(3)}>
                    {getTranslatedText('insurance_recommend')}
                  </div>
                  <div className={'question ' + ((selectionMenu === 4) ? 'active' : '')}
                    onClick={() => setSelectionMenu(4)}>
                    {getTranslatedText('insurance_stop')}
                  </div>
                  <div className={'question ' + ((selectionMenu === 5) ? 'active' : '')}
                    onClick={() => setSelectionMenu(5)}>
                    {getTranslatedText('insurance_how_refund')}
                  </div>
                </div>
                <div className='right'>
                  {selectionMenu === 1 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_who')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_who_answer') }} />
                    </div>
                  )}
                  {selectionMenu === 2 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_long')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_long_answer') }} />
                    </div>
                  )}
                  {selectionMenu === 3 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_recommend')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_recommend_answer') }} />
                    </div>
                  )}
                  {selectionMenu === 4 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_stop')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_stop_answer') }} />
                    </div>
                  )}
                  {selectionMenu === 5 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_how_refund')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_how_refund_answer') }} />
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </Layout.Content>
          <Layout.Footer className='footer__container'>
            <Row gutter={16}></Row>
          </Layout.Footer>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  );
};

export default CourseInformation;